<template>
    <div class="flex-grow flex flex-col gap-8">
        <div class="p-6 lg:p-12">
            <h2 class="h2 text-primary mt-0">Passwort vergessen</h2>
            <div id="registerSuccess" style="display: none">Ihre Registrierung war erfolgreich! Überprüfen Sie ihre
                E-Mails um sich anzumelden</div>
            <div style="--gap: 1rem" id="user-login" class="space-y-6 flex flex-col">
                <div class="flex items-center gap-[var(--gap)] flex-wrap">
                    <div class="gap-3 w-full md:w-[calc(50%-var(--gap))] flex flex-col minimal-input">
                        <v-text-field v-model="email" type="email" name="loginName" autocomplete="email" single-line
                            label="E-Mail*"></v-text-field>
                    </div>
                </div>
                <v-btn :loading @click="sendReset" class="w-full lg:w-[calc(50%-var(--gap))]" type="submit"
                    :disabled="!email">Passwort zurücksetzen</v-btn>
                <p v-if="!loading && message" v-text="message"></p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

const loading = ref(false);
const message = ref("");

const csrfName = ref("");
const csrfToken = ref("");

const email = ref("");

const emit = defineEmits(["change"]);

const rules = {
    required: (value) => !!value || "Required."
};

const sendReset = async () => {
    loading.value = true;
    const res = await fetch("/actions/users/send-password-reset-email", {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
        },
        body: JSON.stringify({
            loginName: email.value,
            [csrfName.value]: csrfToken.value
        })
    }).then(res => res.json())
    setTimeout(() => {
        loading.value = false;

        if (res.success) {
            message.value = "E-Mail wurde versendet";
        } else {
            message.value = res.message;
        }
    }, 1000);

};

onMounted(() => {
    csrfName.value = window.csrfTokenName;
    csrfToken.value = window.csrfTokenValue;
});
</script>
