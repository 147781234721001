<template>
  <div class="flex flex-col md:flex-row md:justify-between w-full">
    <div v-for="(item, index) in items" class="flex flex-col text-center md:text-left md:max-w-[25%]">

      <template v-if="item.children[0].typeHandle == 'link'">
        <div class="border-x-0 border-t-darkgray border-t-2" :class="`block md:hidden`">
          <div class="relative flex align-center justify-between py-5 z-10" @click="toggle(index)">
            <h4 class="h4 text-left leading-normal" v-text="item.footerHeading"></h4>
            <chevron-down :class="`w-6 ease-in-out duration-300 ${isOpen[index] ? 'rotate-180' : ''}`"></chevron-down>
          </div>
          <div :class="`block relative ${isOpen[index] ? 'h-auto opacity-1 ease-in-out duration-500' : 'h-0 opacity-0 -z-10'}`" v-show="isOpen">
            <ul class="pb-5">
              <template v-for="child in item.children">
                <li class="text-left pb-2 hover:text-primary">
                  <template v-if="!child.footerLink[0].isEmpty">
                    <router-link v-if="child.footerLink[0].isElement && child.footerLink[0].element " active-class="underline" :aria-label="child.footerLink[0].linkText"
                                 :to="FormatLink(child.footerLink[0].element.uri)">
                      {{ child.footerLink[0].linkText }}
                    </router-link>
                    <template v-else-if="child.footerLink[0].linkUrl == '/user'">
                      <router-link v-if="auth" :to="auth ? '/user' : '/user/login'">
                        <span class="cursor-pointer">{{ child.footerLink[0].linkText }}</span>
                      </router-link>
                      <a class="block cursor-pointer" v-else @click="openLogin.open = true">
                        {{ child.footerLink[0].linkText }}
                      </a>
                    </template>
                    <a v-else :href="child.footerLink[0].url" :target="child.footerLink[0].target">
                      {{ child.footerLink[0].linkText }}
                    </a>
                  </template>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="hidden md:block">
          <h4 class="mb-4" v-text="item.footerHeading"></h4>
          <ul class="space-y-2">
            <template v-for="child in item.children">
              <li class="text-left hover:text-primary">
                <template v-if="!child.footerLink[0].isEmpty">
                  <router-link v-if="child.footerLink[0].isElement  && child.footerLink[0].element" active-class="underline" :aria-label="child.footerLink[0].linkText"
                               :to="FormatLink(child.footerLink[0].element.uri)">
                    {{ child.footerLink[0].linkText }}
                  </router-link>
                  <template v-else-if="child.footerLink[0].linkUrl == '/user'">
                    <router-link v-if="auth" :to="auth ? '/user' : '/user/login'">
                      <span class="cursor-pointer">{{ child.footerLink[0].linkText }}</span>
                    </router-link>
                    <a class="block cursor-pointer" v-else @click="openLogin.open = true">
                      {{ child.footerLink[0].linkText }}
                    </a>
                  </template>
                  <a v-else :href="child.footerLink[0].url" :target="child.footerLink[0].target">
                    {{ child.footerLink[0].linkText }}
                  </a>
                </template>
              </li>
            </template>
          </ul>
        </div>
      </template>

      <template v-else>
        <h4 class="mb-4" v-text="item.footerHeading"></h4>
        <ul class="space-y-2">
          <template v-for="child in item.children">
            <p v-if="child.typeHandle == 'text'" class="text-sm max-w-[70%] mx-auto sm:max-w-max lg:mx-0 mb-8" v-html="child.footerText">
            </p>
            <li v-else-if="child.typeHandle == 'icons'">
              <ul class="flex items-center gap-3 my-6 xl:mb-12 justify-center md:justify-start flex-wrap">
                <li v-for="icon in child.footerIcons" class="flex-grow max-w-6 aspect-square text-black">
                  <template v-if="!icon.iconLink[0].isEmpty">
                    <router-link v-if="icon.iconLink[0].isElement" :aria-label="icon.iconLink[0].linkText"
                      :to="FormatLink(icon.iconLink[0].element.uri)">
                      <span class="icon icon-s text-black" v-html="icon.icon.inline"></span>
                      <span class="hidden"> {{ icon.iconLink[0].element.title }}</span>
                    </router-link>
                    <a v-else :href="icon.iconLink[0].url" :aria-label="icon.iconLink[0].linkText" :target="icon.iconLink[0].target">
                      <span class="icon icon-s text-black" v-html="icon.icon.inline"></span>
                      <!-- <span class="hidden">{{ icon.iconLink[0].linkText }}</span> -->
                    </a>
                  </template>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FormatLink } from '@/util/formater';
import { FooterNavigation } from '@/types';
import {computed, ref, watch} from "vue";
import ChevronDown from "@/icons/ChevronDown.vue";
import {openLogin} from "@/stores/login";

const props = defineProps<{
  items: FooterNavigation[];
  auth: boolean;
}>()

const isOpen = ref([]);

const toggle = (index) => {
  isOpen.value[index] = !isOpen.value[index];
}

</script>