<template>
  <template v-if="initial">
    <slot />
  </template>
  <Sections
    v-else-if="!loading && blocks && blocks.length > 0 && !initial"
    :blocks="blocks"
  />
  <LoadingPage v-else-if="loading" />
  <Error v-else />
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import { useHead } from "@unhead/vue";
import { onMounted, ref, watch } from "vue";
import { AccessDataByHandle } from "@/util/accesser";
import { defineAsyncComponent } from "vue";
import LoadingPage from "@/pages/Blocks/components/Loading.vue";
import initial from "@/stores/initial";

import type { Block } from "@/types";
import Sections from "@/includes/Sections/Sections.vue";

const Error = defineAsyncComponent(() => import("@/components/Error/Error.vue"));

const route = useRoute();
const blocks = ref<Block[]>([]);
const seo = ref(null);
const loading = ref(true);

const props = defineProps<{
  auth: boolean;
  user?: any;
}>();

watch(
  () => route?.path,
  (query) => {
    if (!initial.value) {
      AccessDataByHandle(route, blocks, seo, loading);
    }
  }
);

onMounted(() => {
  if (!initial.value) {
    AccessDataByHandle(route, blocks, seo, loading);
  }
});

// docs => https://unhead.unjs.io/setup/vue/best-practices#avoid-watch-and-usehead
useHead({
  title: () => seo.value?.metaTitle,
  meta: [
    {
      name: "title",
      content: () => seo.value?.metaTitle,
    },
    {
      property: "og:title",
      content: () => seo.value?.metaTitle,
    },
    {
      name: "twitter:title",
      content: () => seo.value?.metaTitle,
    },
    {
      name: "description",
      content: () => seo.value?.metaDescription,
    },
    {
      property: "og:description",
      content: () => seo.value?.metaDescription,
    },
    {
      name: "twitter:description",
      content: () => seo.value?.metaDescription,
    },
    {
      name: "image",
      content: () =>
        seo.value &&
        seo.value?.metaImage &&
        seo.value?.metaImage[0] &&
        seo.value?.metaImage[0].url
          ? window.location.origin + seo.value?.metaImage[0].url
          : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      property: "og:image",
      content: () =>
        seo.value &&
        seo.value?.metaImage &&
        seo.value?.metaImage[0] &&
        seo.value?.metaImage[0].url
          ? window.location.origin + seo.value?.metaImage[0].url
          : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      property: "og:image:type",
      content: () =>
        seo.value &&
        seo.value?.metaImage &&
        seo.value?.metaImage[0] &&
        seo.value?.metaImage[0].url
          ? seo.value?.metaImage[0].transFormat ?? seo.value?.metaImage[0].format
          : seo.value?.globalMetaImage.transFormat ?? seo.value?.globalMetaImage.format,
    },
    {
      property: "og:image:width",
      content: () =>
        seo.value &&
        seo.value?.metaImage &&
        seo.value?.metaImage[0] &&
        seo.value?.metaImage[0].url
          ? seo.value?.metaImage[0].transWidth ?? seo.value?.metaImage[0].width
          : seo.value?.globalMetaImage.transWidth ?? seo.value?.globalMetaImage.width,
    },
    {
      property: "og:image:height",
      content: () =>
        seo.value &&
        seo.value?.metaImage &&
        seo.value?.metaImage[0] &&
        seo.value?.metaImage[0].url
          ? seo.value?.metaImage[0].transHeight ?? seo.value?.metaImage[0].height
          : seo.value?.globalMetaImage.transHeight ?? seo.value?.globalMetaImage.height,
    },
    {
      name: "twitter:image",
      content: () =>
        seo.value &&
        seo.value.metaImage &&
        seo.value.metaImage[0] &&
        seo.value.metaImage[0].url
          ? window.location.origin + seo.value?.metaImage[0].url
          : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      name: "og:site_name",
      content: () => seo.value?.metaTitle,
    },
    {
      property: "og:url",
      content: () => window.location.href,
    },
  ],
});
</script>
