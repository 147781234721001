<template>
  <div class="space-y-12" v-if="user">
    <h1 class="mt-12">Ändern Sie Ihr Profil{{ user ? ", " + user.fullName : "" }}</h1>
    <form
      id="editForm"
      class="bg-gray p-6 rounded-lg w-full lg:min-w-[700px] lg:w-[60%] space-y-12"
      v-on:submit="handleSubmit"
      method="post"
      accept-charset="UTF-8"
      enctype="multipart/form-data"
    >
      <input
        type="hidden"
        id="full-name"
        class=""
        name="fullName"
        :value="user.firstName + ' ' + user.lastName"
        autocomplete="name"
      />
      <input type="hidden" :name="csrfName" :value="csrfToken" />

      <input id="userId" type="hidden" name="userId" v-if="user" :value="user.id" />

      <div v-if="user.photo">
        <FilesInput :image="user.photo.url" />
      </div>
      <div class="space-y-6">
        <h3>Ihre Kontodaten</h3>
        <div class="grid lg:grid-cols-2 gap-6">
          <div class="col-start-1 col-end-2 minimal-input">
            <v-select
              :items="['Divers', 'Herr', 'Frau']"
              bg-color=""
              label="Anrede"
              v-model="user.salutation"
            />
          </div>
          <div class="col-start-1 col-end-2 minimal-input">
            <v-text-field
              :rules="[rules.required]"
              single-line
              label="Vorname*"
              v-model="user.firstName"
            ></v-text-field>
          </div>
          <div class="col-start-1 col-end-2 md:col-start-2 md:col-end-3 minimal-input">
            <v-text-field
              :rules="[rules.required]"
              single-line
              label="Nachname*"
              v-model="user.lastName"
            ></v-text-field>
          </div>
          <div class="col-start-1 col-end-2 minimal-input">
            <v-text-field
              single-line
              label="Telefonnummer"
              v-model="user.addresses.phoneNumber"
            ></v-text-field>
          </div>
          <div class="col-start-1 col-end-2 md:col-start-2 md:col-end-3 minimal-input">
            <v-text-field
              :rules="[rules.required]"
              name="email"
              autocomplete="email"
              type="email"
              single-line
              label="E-Mail*"
              v-model="user.email"
            ></v-text-field>
          </div>
        </div>
      </div>
      <div class="space-y-6">
        <h3>Adresse</h3>
        <div class="grid lg:grid-cols-12 gap-6">
          <div class="col-start-1 col-end-2 md:col-end-13 minimal-input">
            <v-text-field
              single-line
              label="Straße und Hausnummer"
              v-model="user.addresses.addressLine1"
            ></v-text-field>
          </div>
          <div class="col-start-1 col-end-2 md:col-end-4 minimal-input">
            <v-text-field
              :rules="[rules.required]"
              single-line
              label="PLZ"
              type="number"
              v-model="user.addresses.postalCode"
            ></v-text-field>
          </div>
          <div class="col-start-1 col-end-2 md:col-start-4 md:col-end-13 minimal-input">
            <v-text-field
              single-line
              label="Stadt"
              v-model="user.addresses.locality"
            ></v-text-field>
          </div>
        </div>
      </div>
      <!-- <div class="space-y-6">
        <h3>Kennwort ändern</h3>
        <div class="grid grid-cols-2 gap-6">
          <div class="col-start-1 col-end-2 minimal-input">
            <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'" :rules="[rules.required, rules.min]" single-line
              hint="Ihr Passwort sollte mindestens 8 Zeichen lang sein." label="Passwort*" name="new-password"
              @click:append="show1 = !show1"></v-text-field>
          </div>
          <div class="col-start-2 col-end-3 minimal-input">
            <v-text-field :rules="[rules.required, rules.min, rules.match]" single-line
              hint="Ihr Passwort sollte mindestens 8 Zeichen lang sein." label="Passwort wiederholen*"
              @click:append="show2 = !show2" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"></v-text-field>
          </div>
          <div class="cols-start-1 col-end-2"></div>
        </div>
      </div> -->
      <div class="space-y-6 minimal-input">
        <h3>Haben Sie bereits ein Grundstück?</h3>
        <v-radio-group inline v-model="user.hasProperty">
          <v-radio label="Nein" :value="false"></v-radio>
          <v-radio label="Ja" :value="true"></v-radio>
        </v-radio-group>
      </div>
      <div class="space-y-6 minimal-input">
        <h3>Newsletter abbonieren?</h3>
        <v-radio-group inline v-model="user.hasNewsletterAbo">
          <v-radio label="Nein" :value="false"></v-radio>
          <v-radio label="Ja" :value="true"></v-radio>
        </v-radio-group>
      </div>
      <v-btn type="submit">Absenden</v-btn>
      <!-- <form method="post" accept-charset="UTF-8" enctype="multipart/form-data">
                <input type="hidden" name="CRAFT_CSRF_TOKEN"
                    value="eJeexhG3v3sJHIkjaEDb1laKZftt6dBuP20n7bexzWnnfK1hWZ2T4VIguJynRZd52bPmud805iizjPN3w1QNPX7QoWUkS87Og4ek9zxmpJFOeACC90aKRIB5CCNYC19ODHyddEe2dulLSvxwSF9TewNx1Ix0M5SSmJ-3UM84vDeDXNdzVTWJXx1KK-_cZUbVbnpGGQza-6FH9o8Jb3bxaF4Hgrduwj3IAI2bLWoZaLjg3IJRlzjfMR-toNguEtr_niCvTujR1YHsANYchu6ST_dhbwpItZQEEX7__LLiwccPXseneE0z4JN-un25TGpCYG1ndjkZ-xB1gRfdcj6xFS8JEktxF770PwXTy_mn_wj8Vdh8wAmjPABi5BAlOm-djCN25lcGcy8=">
                <input type="hidden" name="action" value="users/save-user">
                <input type="hidden" name="userId" value="56">

                <label for="full-name">Full Name</label>
                <input type="text" id="full-name" class="" name="fullName" value="Antonii Adamovych test"
                    autocomplete="name">



                <label for="photo">Upload a new photo</label>
                <input type="file" id="photo" name="photo" accept="image/png,image/jpeg">


                <label for="email">Email</label>
                <input type="text" id="email" class="" name="email" value="adamovych@men-at-work.de"
                    autocomplete="email">



                <label for="new-password">New Password</label>
                <input type="password" id="new-password" class="" name="newPassword" autocomplete="new-password">


                <label for="current-password">Current Password</label>
                <input type="password" id="current-password" class="" name="password" autocomplete="current-password">


                <button>Save Profile</button>
            </form> -->
    </form>
  </div>
</template>

<script setup lang="ts">
import { loaded } from "@/stores/loading";
import FilesInput from "./components/FilesInput.vue";
import errorStore from "@/stores/error";

import { ref, onMounted, computed } from "vue";

import type { User } from "@/types/user";
import { useUserStore } from "@/stores/userStore";

const userStore = useUserStore();

const user = computed(() => userStore.getUserData as User);

const props = defineProps<{
  user: User | null;
}>();

const csrfName = ref("");
const csrfToken = ref("");

const password = ref("");

const rules = {
  required: (value) => !!value || "Required.",
  match: (value) => value === password.value || "Passwörter stimmen nicht",
  min: (v) => v.length >= 8 || "Ihr Passwort sollte mindestens 8 Zeichen lang sein.",
};

onMounted(() => {
  csrfName.value = window.csrfTokenName;
  csrfToken.value = window.csrfTokenValue;

  loaded.value.animation = true;
  setTimeout(() => {
    loaded.value.active = false;
  }, 300);
});

function handleSubmit(event) {
  event.preventDefault();

  try {
    fetch("/actions/_data-sync/user/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: user.value.id,
        email: user.value.email,
        salutation: user.value.salutation,
        firstName: user.value.firstName,
        lastName: user.value.lastName,
        addressLine1: user.value.addresses.addressLine1,
        postalCode: user.value.addresses.postalCode,
        locality: user.value.addresses.locality,
        phoneNumber: user.value.addresses.phoneNumber,
        mobilePhone: user.value.addresses.mobilePhone,
        hasProperty: user.value.hasProperty,
        hasNewsletterAbo: user.value.hasNewsletterAbo,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        errorStore.value.message = "Erfolgreich gespeichert";
        errorStore.value.active = true;
      });
  } catch (e) {
    console.error(e);
  }
}
</script>
