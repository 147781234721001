<template>
  <div class="space-y-6 pt-2 lg:pt-9">
    <h3 class="h2-s text-primary">Hausdaten</h3>
    <div class="w-full flex flex-wrap gap-[var(--gap)] lg:gap-[var(--gap-l)]" style="--gap:1rem; --gap-l:1.5rem">
      <div v-if="house.houseTypes && house.houseTypes.length > 0" :class="styleNames">
<!--        <HomeIcon class="w-8 text-[#888]" />-->
        <div class="w-8 [&_svg]:!stroke-1 text-[#888] shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.398 30.831">
            <path id="Pfad_40437" data-name="Pfad 40437" d="M2.25,17.271,16.287,3.232a1.765,1.765,0,0,1,2.494,0L32.82,17.271M5.777,13.743V29.616A1.764,1.764,0,0,0,7.541,31.38h6.467V23.737a1.764,1.764,0,0,1,1.764-1.764H19.3a1.764,1.764,0,0,1,1.764,1.764V31.38h6.467a1.764,1.764,0,0,0,1.764-1.764V13.743M11.656,31.38H24.589" transform="translate(-0.836 -1.549)" fill="none" stroke="#888" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          </svg>

        </div>
        <div class="mt-2">
          <h3>Baustil</h3>
          <p v-html="transformSlash(house.houseTypes.map((entry) => entry.title).join(','))"></p>
        </div>
      </div>
      <div v-if="house.totalLivingArea" :class="styleNames">
        <div class="w-8 [&_svg]:!stroke-1 text-[#888] shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.797 30.797">
            <path id="Pfad_40438" data-name="Pfad 40438"
              d="M3.75,31.719V24.091m0,7.628h7.628m-7.628,0,8.9-8.9M31.719,3.75H24.091m7.628,0v7.628m0-7.628-8.9,8.9"
              transform="translate(-2.336 -2.336)" fill="none" stroke="currentColor" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="2" />
          </svg>
        </div>
        <div class="mt-2">
          <h3>Wohnfläche</h3>
          <p v-text="house.totalLivingArea + 'm²'"></p>
        </div>
      </div>
      <div v-if="house.houseStyles && house.houseStyles.length > 0" :class="styleNames">
        <div class="w-8 [&_svg]:!stroke-1 text-[#888] shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.116 30.5">
            <path id="Pfad_40411" data-name="Pfad 40411" d="M11.656,31.218V23.576a1.764,1.764,0,0,1,1.764-1.764h3.527a1.764,1.764,0,0,1,1.764,1.764v7.642m0,0h7.055V3.854M18.711,31.218H30.468V15.15M2.25,31.218H4.6m28.218,0H4.6M2.25,12.406,9.3,9.841M28.117,3l-2.352.854m0,9.727,4.7,1.568m2.352.784-2.352-.784M9.3,9.841V3H4.6V31.218M9.3,9.841,25.765,3.854" transform="translate(-0.969 -1.719)" fill="none" stroke="#888" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          </svg>

        </div>
        <div class="mt-2">
          <h3>Gebäudeart</h3>
          <p v-html="transformSlash(house.houseStyles.map((entry) => entry.title).join(','))"></p>
        </div>
      </div>
      <div v-if="house.externalDimensionsLength && house.externalDimensionsWidth" :class="styleNames">
        <CubeTransparentIcon class="w-8 text-[#888] shrink-0" />
        <div class="mt-2">
          <h3>Außenmaße</h3>
          <p v-text="`${house.externalDimensionsWidth}m x ${house.externalDimensionsLength} m `"></p>
        </div>
      </div>
      <div v-if="house.methodOfConstructions && house.methodOfConstructions.length > 0" :class="styleNames">
        <div class="w-8 [&_svg]:!stroke-1 text-[#888] shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.659 30.455">
            <path id="Pfad_40419" data-name="Pfad 40419"
              d="M21.531,11.464c-.123.152-.216.291-.332.407Q12.891,20.184,4.579,28.494a2.606,2.606,0,0,1-3.255.591,2.572,2.572,0,0,1-.8-3.8,5.739,5.739,0,0,1,.5-.55q8.3-8.3,16.594-16.592c.166-.166.3-.3.2-.591-.051-.144.1-.417.235-.56.545-.578,1.119-1.127,1.773-1.778a22.109,22.109,0,0,0-2.08-1.259c-2.124-.992-4.277-1.923-6.414-2.886a.99.99,0,0,1-.442-.338c-.213-.36.087-.74.572-.733.686.011,1.372.04,2.057.067,1.837.074,3.676.126,5.511.236a9.375,9.375,0,0,1,4.617,1.585,3.683,3.683,0,0,1,.544.5q1.546,1.54,3.087,3.084c.415.417.417.63.022,1.041-.207.216-.506.434-.316.768a1.2,1.2,0,0,0,.952.758c.4.019.8-.039,1.192.019a2.278,2.278,0,0,1,1.024.344,11.958,11.958,0,0,1,1.3,1.256.5.5,0,0,1-.02.769q-2.149,2.159-4.307,4.31a.533.533,0,0,1-.834,0,9.842,9.842,0,0,1-1.143-1.181,3.175,3.175,0,0,1-.414-1.126,2.372,2.372,0,0,1-.007-.82,1.2,1.2,0,0,0-.505-1.14c-.327-.272-.559-.289-.867,0-.29.272-.571.555-.846.842s-.545.483-.97.156M18.624,8.606c-.083.079-.164.152-.241.229Q10,17.215,1.624,25.6a2.892,2.892,0,0,0-.3.336A1.471,1.471,0,0,0,1.2,27.6a1.472,1.472,0,0,0,1.421.807,1.745,1.745,0,0,0,1.21-.62Q12.2,19.4,20.584,11.021c.083-.083.156-.177.211-.239L18.624,8.606m7.7-2.618c-.83-.826-1.689-1.616-2.471-2.476a6.126,6.126,0,0,0-3.666-1.958c-1.51-.235-3.055-.256-4.585-.354-.467-.03-.937,0-1.436,0,.124.062.229.119.337.167q1.82.815,3.641,1.629a8.748,8.748,0,0,1,2.585,1.8c.353.35.351.587.007.95-.123.13-.251.253-.378.379L19.082,7.4l2.969,2.969c.169-.191.353-.432.569-.64a1.583,1.583,0,0,1,2.313-.019,2.269,2.269,0,0,1,.818,2.2,1.737,1.737,0,0,0,.893,1.688l3.622-3.617a1.741,1.741,0,0,0-1.856-.89,2.4,2.4,0,0,1-.493,0,2.214,2.214,0,0,1-1.84-1.278,1.516,1.516,0,0,1,.252-1.825"
              transform="translate(0.507 0.5)" fill="#888" stroke="#888" stroke-linecap="round" stroke-linejoin="round"
              stroke-width="1" />
          </svg>
        </div>
        <div class="mt-2">
          <h3>Bauweise</h3>
          <p v-html="transformSlash(house.methodOfConstructions.map((entry) => entry.title).join(','))"></p>
        </div>
      </div>
      <div v-if="house.numberOfRooms" :class="styleNames">
        <CubeIcon class="w-8 text-[#888] shrink-0" />
        <div class="mt-2">
          <h3>Anzahl Räume</h3>
          <p v-text="house.numberOfRooms"></p>
        </div>
      </div>
      <div v-if="house.facades && house.facades.length > 0" :class="styleNames">
        <div class="w-8 [&_svg]:!stroke-1 text-[#888] shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.969 29.97">
            <path id="Pfad_40416" data-name="Pfad 40416"
              d="M4.706,29.263a5.827,5.827,0,0,0,8.241,0l9.946-9.948M8.827,30.969A5.827,5.827,0,0,1,3,25.142V4.748A1.749,1.749,0,0,1,4.748,3h8.158a1.749,1.749,0,0,1,1.748,1.748v6.327M8.827,30.969a5.827,5.827,0,0,0,5.827-5.827V11.075M8.827,30.969H29.221a1.749,1.749,0,0,0,1.748-1.748V21.063a1.749,1.749,0,0,0-1.748-1.748H22.893m-8.24-8.24L19.129,6.6a1.747,1.747,0,0,1,2.471,0l5.768,5.769a1.744,1.744,0,0,1,0,2.471l-4.473,4.475M8.827,25.142h.012v.012H8.827Z"
              transform="translate(-2 -2)" fill="none" stroke="currentColor" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="2" />
          </svg>
        </div>
        <div class="mt-2">
          <h3>Fassade</h3>
          <p v-html="transformSlash(house.facades.map((entry) => entry.title).join(','))"></p>
        </div>
      </div>
      <div v-if="house.numberOfFloors" :class="styleNames">
        <div class="w-8 [&_svg]:!stroke-1 text-[#888] shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.398 30.831">
            <g id="Gruppe_6189" data-name="Gruppe 6189" transform="translate(-1429.586 -2077.451)">
              <path id="Pfad_40440" data-name="Pfad 40440"
                d="M2.25,17.271,16.287,3.232a1.765,1.765,0,0,1,2.494,0L32.82,17.271M5.777,13.743V29.616A1.764,1.764,0,0,0,7.541,31.38H27.529a1.764,1.764,0,0,0,1.764-1.764V13.743M11.656,31.38H24.589"
                transform="translate(1428.75 2075.902)" fill="none" stroke="currentColor" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" />
              <line id="Linie_177" data-name="Linie 177" x2="24" transform="translate(1434.5 2090.5)" fill="none"
                stroke="currentColor" stroke-linecap="round" stroke-width="2" stroke-dasharray="1 3.5" />
              <line id="Linie_178" data-name="Linie 178" x2="24" transform="translate(1434.5 2098.5)" fill="none"
                stroke="currentColor" stroke-linecap="round" stroke-width="2" stroke-dasharray="1 3.5" />
            </g>
          </svg>
        </div>
        <div class="mt-2">
          <h3>Geschosse</h3>
          <p v-text="house.numberOfFloors"></p>
        </div>
      </div>
      <div v-if="house.roofShapes && house.roofShapes.length > 0" :class="styleNames">
        <div class="w-8 [&_svg]:!stroke-1 text-[#888] shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.797 15.888">
            <g id="Gruppe_6095" data-name="Gruppe 6095" transform="translate(-1429.586 -1899.362)">
              <path id="Pfad_40418" data-name="Pfad 40418"
                d="M2.25,16.032,15.093,3.188a1.614,1.614,0,0,1,2.282,0L30.219,16.032M3.76,15.256H29.328"
                transform="translate(1428.75 1897.804)" fill="none" stroke="currentColor" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" />
              <line id="Linie_166" data-name="Linie 166" y1="8" transform="translate(1454.5 1900.5)" fill="none"
                stroke="currentColor" stroke-linecap="round" stroke-width="2" />
              <line id="Linie_167" data-name="Linie 167" y1="4" transform="translate(1450.5 1900.5)" fill="none"
                stroke="currentColor" stroke-linecap="round" stroke-width="2" />
              <line id="Linie_168" data-name="Linie 168" x1="4" transform="translate(1450.5 1900.5)" fill="none"
                stroke="currentColor" stroke-linecap="round" stroke-width="2" />
            </g>
          </svg>
        </div>
        <div class="mt-2">
          <h3>Dachform</h3>
          <p v-html="transformSlash(house.roofShapes.map((entry) => entry.title).join(','))"></p>
        </div>
      </div>
      <div v-if="house.energeticStandards && house.energeticStandards.length > 0" :class="styleNames">
        <div class="w-8 [&_svg]:!stroke-1 text-[#888] shrink-0">
          <svg id="Gruppe_6031" data-name="Gruppe 6031" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 27.969 27.37">
            <defs>
              <clipPath id="clip-path-energetics">
                <rect id="Rechteck_1235" data-name="Rechteck 1235" width="27.969" height="27.37" fill="currentColor" />
              </clipPath>
            </defs>
            <g id="Gruppe_6030" data-name="Gruppe 6030" clip-path="url(#clip-path-energetics)">
              <path id="Pfad_40417" data-name="Pfad 40417"
                d="M25.339,3.951A14.366,14.366,0,0,0,22.28,2.592a17.706,17.706,0,0,0-10.266-.024A18.481,18.481,0,0,0,6.8,4.8a10.552,10.552,0,0,0-4.974,9.1c-.024.986.125,1.976.2,2.963a1.783,1.783,0,0,0,.047.22A.556.556,0,0,0,2.157,17a17.277,17.277,0,0,1,5.828-4.811,24.631,24.631,0,0,1,5.027-2.043.92.92,0,0,1,1.068.347.862.862,0,0,1-.043,1.071,1.435,1.435,0,0,1-.6.357,23.439,23.439,0,0,0-6.559,3.1,13.018,13.018,0,0,0-3.5,3.428,8.9,8.9,0,0,0-1.431,6.5c.063.414.154.823.242,1.232a.916.916,0,0,1-.663,1.152.9.9,0,0,1-1.108-.7,11.349,11.349,0,0,1,.262-6.962A.727.727,0,0,0,.7,19.265a17.216,17.216,0,0,1-.3-8.376A11.9,11.9,0,0,1,6.479,2.857,21.636,21.636,0,0,1,18.4.035a16.877,16.877,0,0,1,8.854,2.983,3.081,3.081,0,0,1,.457.371.886.886,0,0,1-.2,1.406,9.026,9.026,0,0,0-3.792,3.986,14.665,14.665,0,0,0-1.173,3.357,21.857,21.857,0,0,1-1.5,4.382A10.936,10.936,0,0,1,14.9,21.9a19.932,19.932,0,0,1-6.165,1.22c-1.321.061-2.649.019-3.973-.005a.905.905,0,0,1-.927-1.025.919.919,0,0,1,1.02-.8,26.4,26.4,0,0,0,4.126-.019,17.561,17.561,0,0,0,6.155-1.461,9.011,9.011,0,0,0,4.844-5.5c.414-1.23.742-2.49,1.091-3.741a12.424,12.424,0,0,1,4.2-6.536.655.655,0,0,0,.067-.083"
                transform="translate(0 0)" fill="currentColor" />
            </g>
          </svg>
        </div>
        <div class="mt-2">
          <h3>Energiestandard</h3>
          <p v-html="transformSlash(house.energeticStandards.map((entry) => entry.title).join(','))"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">

/*
  TODO: Setup icons import

  TODO: Translation for headings
*/
import Hause from "@/icons/Hause.vue";
import { HomeIcon, CubeTransparentIcon, CubeIcon } from "@heroicons/vue/24/outline";
import type { HouseEntry } from "@/types";

const styleNames = "flex flex-grow items-center w-[calc(50%-var(--gap))] gap-3 [&_svg]:flex-shrink-0";

const props = defineProps<{
  house: HouseEntry;
}>();

function transformSlash(value: string) {
  return value.replace("/", "/<wbr>");
}
</script>