<template>
  <div id="infos-content" aria-labelledby="infos-control" ref="full" role="" :class="{'max-h-[110px] after:opacity-100': !open, 'max-h-[var(--height)] after:opacity-0': open}" class="mt-4 overflow-hidden h-max content transition-[max-height] duration-300 relative z-0 after:absolute after:bottom-0 after:w-full after:h-[100%] after:bg-gradient-to-t after:from-white after:left-0 after:transition-opacity after:z-10 after:duration-500 lg:max-h-[max-content] lg:after:hidden">
    <div ref="content" class="space-y-4" v-html="description">
    </div>
  </div>
  <button id="infos-control" aria-controls="infos-content" :aria-expanded="open" @click="open = !open" type="button" class="mx-auto py-2 flex items-center gap-1 lg:hidden">
    <span class="accordion-title" v-text="`${ open ? 'weniger lesen': 'weiterlesen' }`"></span>
    <div :class="{'rotate-0': !open, 'rotate-180': open}" class="transition-transform duration-300">
      <v-icon icon="mdi-chevron-down"></v-icon>
    </div>
  </button>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

const open = ref(false);

// html elements
const content = ref<HTMLElement | null>(null);
const full = ref(null);

onMounted(() => {
  setProperty();
  window.addEventListener("resize", setProperty);
});

function setProperty() {
  if(content.value === null || full.value === null) return;
  full.value.style.setProperty("--height", `${content.value.getBoundingClientRect().height}px`);
}

const props = defineProps<{
  description: string;
}>();
</script>