<template>
  <section class="full hero full-end layout">
    <div :class="`full full-end layout w-full lg:full lg:full-end relative z-0 ${img && img.length > 0 && img[0].url ? 'aspect-[4] lg:aspect-[5] xl:aspect-[6]' : ''}`">
      <div class="absolute full h-full full-end top-0 left-0 w-full after:absolute after:w-full after:rounded-lg after:h-full after:top-0 after:left-0 after:bg-gradient-to-t after:from-black after:opacity-50 after:z-5">
        <Image v-if="img && img.length > 0 && img[0].url" :src="img[0].url" :srcset="img[0].srcset"
               class="w-full h-full opacity-90 rounded-lg object-cover" :alt="img[0].alt ? img[0].alt : ''"
               :title="img[0].title ? img[0].title : ''" :img="img[0]" :isNotLazy="true" />
      </div>
      <div class="relative z-10 text-white text-center h-full flex items-center justify-center py-12 md:py-20 lg:py-24 xl:py-10">
        <h1 v-if="heading" class="h1">{{ heading }}</h1>
      </div>
    </div>
  </section>
  <section class="lg:layout lg:layout-content-start lg:layout-content-end 2xl:small 2xl:small-end marginBottom-standard marginTop-standard">
    <div class="flex justify-center gap-4 md:gap-10 min-w-[200px] w-full !max-w-[500px] mx-auto bg-white border-black border-2 rounded-full minimal-input">
      <v-text-field v-model="search" bgColor="#ffff" base-color="#fff" name="search"
                    class="w-[55%]  no-focus-visible"
                    single-line label="Suche"></v-text-field>
      <v-btn @click="searchResults"
                  class="hover:[&>*]:!text-white">suchen</v-btn>
    </div>
  </section>
  <section class="layout lg:layout-content-start lg:layout-content-end 2xl:small 2xl:small-end marginBottom-standard">
    <template v-if="searchResultsArray && search != ''" v-for="result in searchResultsArray">
      <div v-if="result && result.title && result.url" class="flex flex-col gap-2 py-3 px-4 rounded-lg bg-gray mb-4" :key="result.id">
          <a :href="`${result.url}`" class="text-primary"><span class="h4">{{ result.title }}</span></a>
          <p v-if="result.metaDescription" class="2xl:w-[90%]">{{ result.metaDescription }}</p>
      </div>
    </template>
  </section>

</template>

<script setup lang="ts">
import { loaded } from "@/stores/loading";
import {onMounted, ref} from "vue";
import client from "@/api/craft";
import {seoData} from "@/api/queries";
import {useHead} from "@unhead/vue";
import type {Image} from "@/types/elements";

let searchResultsArray = ref([]);
const seo = ref(<seoData>{
  metaDescription: "Suche nach Ergebnissen",
  metaTitle: "Suche nach Ergebnissen | Meisterstück-HAUS",
  globalMetaImage: {'url': '/files/images/SEO/MS-logo.jpg', 'width': '322px', 'height': '139px', 'format': 'jpg'}
});
let search = ref('');

const props = defineProps<{
  heading: string;
  img: Image[];
}>();

let heading = ref<string>("");
let img = ref<Image[]>();


getPageData();
onMounted(() => {
  loaded.value.animation = true;
  setTimeout(() => {
    loaded.value.active = false;
  }, 300);
});

async function searchResults() {
  let searchResult = await client.getSearchResults(search.value);
  if (searchResult && searchResult.data.entries.length > 0) {
    searchResultsArray.value = searchResult.data.entries;
  } else {
    return null;
  }
}

async function getPageData() {
  let dataResults = await client.getSearchPageData();
  let globalData = await client.GetGeneralGlobals();

  if(dataResults && dataResults.data.length > 0) {
    seo.value = {
      metaTitle: dataResults.data.metaTitle ?? seo.value.metaTitle,
      metaDescription: dataResults.data.metaDescription ?? seo.value.metaDescription,
      metaImage: dataResults.data.metaImage,
    }
  }
  if(globalData && globalData.data.globalSet && globalData.data.globalSet.metaImage) {
    seo.value.globalMetaImage = globalData.data.globalSet.metaImage[0];
  }

  heading.value = dataResults.data.entries[0].heading ?? null;
  img.value = dataResults.data.entries[0].img ?? null;
}

useHead({
  title: () => seo.value?.metaTitle,
  meta: [
    {
      name: "title",
      content: () => seo.value?.metaTitle,
    },
    {
      property: "og:title",
      content: () => seo.value?.metaTitle,
    },
    {
      name: "twitter:title",
      content: () => seo.value?.metaTitle,
    },
    {
      name: "description",
      content: () => seo.value?.metaDescription,
    },
    {
      property: "og:description",
      content: () => seo.value?.metaDescription,
    },
    {
      name: "twitter:description",
      content: () => seo.value?.metaDescription,
    },
    {
      name: "image",
      content: () =>
          seo.value && seo.value?.metaImage && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
              ? window.location.origin + seo.value?.metaImage[0].url
              : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      property: "og:image",
      content: () =>
          seo.value && seo.value?.metaImage && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
              ? window.location.origin + seo.value?.metaImage[0].url
              : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      property: "og:image:type",
      content: () =>
          seo.value && seo.value?.metaImage && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
              ? (seo.value?.metaImage[0].transFormat ?? seo.value?.metaImage[0].format)
              : (seo.value?.globalMetaImage.transFormat ?? seo.value?.globalMetaImage.format),
    },
    {
      property: "og:image:width",
      content: () =>
          seo.value && seo.value?.metaImage && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
              ? (seo.value?.metaImage[0].transWidth ?? seo.value?.metaImage[0].width)
              : (seo.value?.globalMetaImage.transWidth ?? seo.value?.globalMetaImage.width),
    },
    {
      property: "og:image:height",
      content: () =>
          seo.value && seo.value?.metaImage && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
              ? (seo.value?.metaImage[0].transHeight ?? seo.value?.metaImage[0].height)
              : (seo.value?.globalMetaImage.transHeight ?? seo.value?.globalMetaImage.height),
    },
    {
      name: "twitter:image",
      content: () =>
          seo.value && seo.value.metaImage && seo.value.metaImage[0] && seo.value.metaImage[0].url
              ? window.location.origin + seo.value?.metaImage[0].url
              : window.location.origin + seo.value?.globalMetaImage.url,
    },
    {
      name: "og:site_name",
      content: () => seo.value?.metaTitle,
    },
    {
      property: "og:url",
      content: () => window.location.href,
    },
  ],
});
</script>