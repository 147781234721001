<template>
  <section
    v-if="youtubeId"
    class="full full-end layout marginTop-standard marginBottom-standard"
  >
    <template v-if="hasConfirmed">
      <iframe
        class="w-full aspect-video block max-w-[1440px] mx-auto w-full"
        anonymous
        type="text/html"
        :src="`https://www.youtube-nocookie.com/embed/${youtubeId}`"
        frameborder="0"
      />
    </template>
    <template v-else>
      <div
        class="w-full space-y-4 bg-gray rounded-lg grid place-content-center px-10 py-4"
      >
        <h2 class="h2-s text-center max-w-[700px]">
          Wir benötigen Ihre Zustimmung, um den YouTube Video-Service zu laden!
        </h2>
        <p class="max-w-[600px] text-center mx-auto">
          Wir verwenden einen Service eines Drittanbieters, um Videoinhalte einzubetten.
          Dieser Service kann Daten zu Ihren Aktivitäten sammeln. Bitte lesen Sie die
          Details durch und stimmen Sie der Nutzung des Service zu, um dieses Video
          anzusehen.
        </p>
        <v-btn @click="OpenCookiebot" class="w-max mx-auto">Akzeptieren</v-btn>
      </div>
    </template>
  </section>
</template>
<script setup lang="ts">
import { ref } from "vue";
import Cookies from "@/util/cookie";

const props = defineProps<{
  youtubeId: string | null;
}>();

const hasConfirmed = ref(false);

const cookieName = "youtubeCookies";

const cookie = Cookies.get(cookieName);

if (cookie && cookie === "true") {
  hasConfirmed.value = true;
}

document.addEventListener("CookiebotOnLoad", () => {
  confirm();
});

window.addEventListener("CookiebotOnAccept", function (e) {
  confirm();
});

const confirm = () => {
  // @ts-ignore
  if (Cookiebot) {
    // @ts-ignore
    hasConfirmed.value = Cookiebot.consent.marketing;
  }
};

const OpenCookiebot = () => {
  // @ts-ignore
  if (Cookiebot) {
    // @ts-ignore
    Cookiebot.show();
  }
};
</script>
