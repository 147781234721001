<template>
    <div class="p-6 lg:p-12">
        <h2 class="font-bold text-primary mb-8 mt-0 text-4xl pr-6"
            v-html="'Werde Teil der<br />Meisterstück-HAUS-Community'">
        </h2>
        <p
            v-text="'Unsere Meisterstück-HAUS-Community bietet Ihnen viele exklusive Vorteile und ist absolut kostenlos. Werden Sie ein Teil der Community.'">
        </p>
        <form id="user-registration" class="space-y-6 flex flex-col" method="post" accept-charset="UTF-8"
            @submit="handleSubmit">
            <input type="hidden" :name="csrfName" :value="csrfToken" />
            <input type="hidden" name="action" value="users/save-user" />
            <input type="hidden" name="fullName" v-bind:value="firstName + ' ' + lastName">

            <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div class="col-start-1 col-end-2 bg-white rounded-full">
                    <div class="minimal-input">
                        <v-select menu-icon="mdi-chevron-down" v-model="salutation" hide-details
                            name="fields[salutation]" :rules="[rules.required]" label="Anrede*"
                            :items="['Divers', 'Herr', 'Frau']">
                            <template v-slot:append-inner="{ isFocused }">
                                <div class="w-7 transition-transform duration-300"
                                    :class="isFocused.value ? 'rotate-180' : 'rotate-0'">
                                    <ChevronDownIcon class="w-full" />
                                </div>
                            </template>
                        </v-select>
                    </div>
                </div>
                <div class="md:col-start-1 md:col-end-2 minimal-input">
                    <v-text-field v-model="firstName" :rules="[rules.required]" single-line name="firstName"
                        label="Vorname*"></v-text-field>
                </div>
                <div class="md:col-start-2 md:col-end-3 minimal-input">
                    <v-text-field v-model="lastName" :rules="[rules.required]" single-line name="lastName"
                        label="Nachname*"></v-text-field>
                </div>
                <div class="md:col-start-1 md:col-end-2 minimal-input">
                    <v-text-field v-model="phoneNumber" single-line type="number" label="Telefonnummer"></v-text-field>
                </div>
                <div class="md:col-start-2 md:col-end-3 minimal-input">
                    <v-text-field v-model="email" :rules="[rules.required]" type="email" name="email" single-line
                        label="E-Mail*"></v-text-field>
                </div>
                <h3 class="pt-6">Adresse</h3>
                <div class="md:col-start-1 md:col-end-3 minimal-input">
                    <v-text-field single-line label="Straße und Hausnummer" v-model="addressLine1"></v-text-field>
                </div>
                <div class="md:col-start-1 md:col-end-2 minimal-input">
                    <v-text-field :rules="[rules.required]" single-line label="PLZ*" type="number"
                        v-model="postalCode"></v-text-field>
                </div>
                <div class="md:col-start-2 md:col-end-3 minimal-input">
                    <v-text-field single-line label="Stadt" v-model="locality"></v-text-field>
                </div>
                <!--                <div class="md:col-start-2 md:col-end-3 minimal-input">-->
                <!--                  <v-text-field single-line v-model="postalCode" type="number" :rules="[rules.required]" label="Postleitzahl*" name="postalCode"></v-text-field>-->
                <!--                </div>-->
                <h3 class="pt-6">Schützen Sie Ihren Account</h3>
                <div class="md:col-start-1 md:col-end-2 minimal-input">
                    <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'" :rules="[rules.required, rules.min]" single-line
                        hint="Ihr Passwort sollte mindestens 8 Zeichen lang sein." label="Passwort*" name="password"
                        @click:append="show1 = !show1"></v-text-field>
                </div>
                <div class="md:col-start-2 md:col-end-3 minimal-input">
                    <v-text-field v-model="passwordRepeat" :rules="[rules.required, rules.min, rules.match]" single-line
                        hint="Ihr Passwort sollte mindestens 8 Zeichen lang sein." label="Passwort wiederholen*"
                        @click:append="show2 = !show2" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"></v-text-field>
                </div>
                <div class="md:col-start-1 md:col-end-2 mt-6 minimal-input">
                    <h3>Haben Sie bereits ein Grundstück?</h3>
                    <v-radio-group inline v-model="hasProperty">
                        <v-radio label="Nein" v-model="radio" name="hasProperty" :value="false"></v-radio>
                        <v-radio label="Ja" v-model="radio" name="hasProperty" :value="true"></v-radio>
                    </v-radio-group>
                </div>
                <div class="md:col-start-1 md:col-end-3 minimal-input">
                    <v-checkbox :rules="[rules.required]" v-model="privacy" class="[&_label]:block"
                        name="fields[acceptedTermsAndConditions]">
                        <template v-slot:label>Hiermit willige ich ein, dass meine Daten zur Bearbeitung meines
                            Anliegens von der Otto Baukmeier Holzbau - Fertigbau GmbH & Co KG und deren
                            Vertriebsbeauftragten verwendet und gespeichert werden dürfen. Die Kontaktaufnahme kann über
                            die von mir angegebenen Kontaktwege erfolgen. Die Informationen zu meinen sonstigen Rechten
                            im Zusammenhang mit der Nutzung meiner personenbezogenen Daten habe ich in der <a
                                href="/datenschutz" class="text-primary hover:tw-text-white"
                                target="_black">Datenschutzerklärung</a> zur Kenntnis genommen.*</template>
                    </v-checkbox>
                </div>
                <div class="md:col-start-1 md:col-end-3 minimal-input">
                    <v-checkbox v-model="hasNewsletterAbo" name="fields[hasNewsletterAbo]" value="1"
                        label="Ja, ich möchte den Newsletter abonnieren. Der Newsletter kann jederzeit wieder abbestellt werden."></v-checkbox>
                </div>
                <div>
                    <v-btn type="submit"
                        :loading
                        :disabled="!privacy || !firstName.length || !lastName.length || passwordRepeat.length < 8 || password.length < 8 || passwordRepeat !== password">
                        Registrierung abschliessen </v-btn>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { ChevronDownIcon } from "@heroicons/vue/24/outline";

import { ref, onMounted } from "vue";
import errorStore from "@/stores/error";

const emit = defineEmits(["success"]);

const loading = ref(false);

const csrfName = ref("");
const csrfToken = ref("");

const show1 = ref(false);
const show2 = ref(false);

const password = ref("");
const passwordRepeat = ref("");
const radio = ref(false);

const firstName = ref("");
const lastName = ref("");
const phoneNumber = ref("");
const email = ref("");

const addressLine1 = ref("");
const postalCode = ref("");
const locality = ref("");
const privacy = ref(false);
const hasProperty = ref();
const hasNewsletterAbo = ref(false);

const salutation = ref("Anrede*");

const rules = {
    required: (value) => !!value || "Required.",
    match: (value) => value === password.value || "Passwörter stimmen nicht",
    min: (v) =>
        v.length > 8 || "Ihr Passwort sollte mindestens 8 Zeichen lang sein.",
};


onMounted(() => {
    csrfName.value = window.csrfTokenName;
    csrfToken.value = window.csrfTokenValue;
});

function handleSubmit(event) {
    event.preventDefault();
    loading.value = true;

    try {
        fetch('/actions/_data-sync/user/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email.value,
                password: password.value,
                salutation: salutation.value,
                firstName: firstName.value,
                lastName: lastName.value,
                addressLine1: addressLine1.value,
                postalCode: postalCode.value,
                locality: locality.value,
                phoneNumber: phoneNumber.value,
                hasProperty: hasProperty.value,
                hasNewsletterAbo: hasNewsletterAbo.value,
                acceptedTermsAndConditions: privacy.value,
            }),
        }).then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    emit('success', {
                        tab: 'login',
                        registerSuccess: true
                    })
                } else {
                    errorStore.value.message = result.message;
                    errorStore.value.active = true;
                }

                loading.value = false;
            });
    } catch (e) {
        console.error(e);
    }
}
</script>
