
import { img } from "."

export const GetAll = `
query GetNavigation {
    houseEntries {
      ... on house_default_Entry {
        id
      }
    }
  }
`

export const Pagination = `
query MyQuery($limit: Int, $offset: Int) {
    houseEntries(limit: $limit, offset: $offset) {
      ... on house_default_Entry {
        id
        title
        houseName
        houseGallery(limit: 1) @transform(handle: "houseGallery") {
            url
            srcset(sizes: ["320w", "640w", "1280w", "1920w"])
        }
        uri
      }
    }
  }
`

export const GetOptions = `
query GetOptions {
  globalSet(handle: "filterOptions") {
    ... on filterOptions_GlobalSet {
      hausFilter {
        ... on hausFilter_option_BlockType {
          id
          heading
          optionType
          optionhandle
          values {
            ... on values_BlockType {
              id
              optionValue
              valueAttribute
              valueHandle
              valueId
              isreference
            }
          }
        }
      }
    }
  }
}
`

export const getAll = (query: string) => {
  return `
    query GetHouseEntries {
      houseEntries${query ? `(${query ? `${query}` : ''})` : '' } {
        ... on house_default_Entry {
          id
        }
      }
    }
  `
}

export const getFiltered = (query: string, limit: number = 0, offset: number = 0) => {
  return `
    query GetHouseEntries {
      houseEntries${limit > 0 || query ? `(${limit > 0 ? `limit: ${limit}, offset: ${offset},`: ''} ${query ? `${query}` : ''})` : '' } {
        ... on house_default_Entry {
          id
          title
          houseName
          houseGallery(limit: 1) @transform(handle: "houseGallery") {
            url
            srcset(sizes: ["320w", "640w", "1280w", "1920w"])
          }
          uri
          realEstateTypes {
            title
          }
          houseStyles {
            title
          }
          totalLivingArea
          promotionalPrice
          pricetyp1from
          pricetyp1to
          pricetyp2from
          pricetyp2to
        }
      }
    }
  `
}