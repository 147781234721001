import { getCache, setCache, syncDatainStorage } from "@/util/cache";
import type { Block, FooterNavigation, FooterServiceNavigation, appointmentOccasions } from "@/types";
import client from "@/api/craft";
import News from "@/stores/news";
import {loaded} from "@/stores/loading";
import Appointments from "@/stores/appointments";

let cache_expire_time = 60 * 60 * 24;
// retrieve cached data or fetch data from the server
export async function AccessDataByHandle(route: any, blocks: any, seo: any, loading: any, fullEntry: any = null) {
    if (route.path.includes("/user")) return;
    const urlParams = new URLSearchParams(window.location.search);
    if (getCache(route.path.toString()) && urlParams.get('x-craft-live-preview') === null) {
        if(blocks != null) {
            blocks.value = JSON.parse(getCache(route.path.toString())) as Block[];
        }
        seo.value = JSON.parse(getCache(route.path.toString() + "_seo"));
        if(fullEntry) {
          fullEntry.value = JSON.parse(getCache(route.path.toString() + "_fullEntry"));
        }
        syncDatainStorage(route.path, blocks, route.path === '/' ? 'startseite' : route.params.pathMatch[route.params.pathMatch.length - 1]);
        loading.value = false;

        return;
    } else {
        return client.GetPageByHandle(route.path === '/' ? 'startseite' : route.params.pathMatch[route.params.pathMatch.length - 1]).then(async (res) => {
            if (res && res.data.entries && res.data.entries.length > 0) {
                const entry = res.data.entries.filter((entry: any) => Object.keys(entry).length > 0);
                const general = await client.GetGeneralGlobals();
                if (entry.length > 0) {
                    if (entry[0].blocks.length > 0) {
                        blocks.value = entry[0].blocks;
                        setCache(route.path.toString(), JSON.stringify(entry[0].blocks), cache_expire_time);
                    }
                    if (entry[0].metaTitle) {
                        seo.value = {
                            metaDescription: entry[0].metaDescription ?? null,
                            metaImage: entry[0].metaImage ?? null,
                            metaTitle: entry[0].metaTitle ?? null,
                            globalMetaImage: general.data.globalSet.metaImage[0] ?? null,
                        }
                        setCache(route.path.toString() + "_seo", JSON.stringify(seo.value), cache_expire_time);
                    }
                    if (fullEntry) {
                        fullEntry.value = entry[0];
                        setCache(route.path.toString() + "_fullEntry", JSON.stringify(fullEntry.value), cache_expire_time);
                    }
                }
                loading.value = false;
                return;
            } else {
                loading.value = false;
            }
        });
    }
}

export async function AccessNavData(navigation: any, staticLinks: any) {
    if (getCache("navigation") && getCache("staticLinks")) {
        navigation.value = JSON.parse(getCache("navigation")) as Block[];
        staticLinks.value = JSON.parse(getCache("staticLinks")) as Block[];
        await syncDatainStorage("navigation", navigation, "navigation");
        await syncDatainStorage("staticLinks", staticLinks, "staticLinks");
        return;
    } else {
        const response = await client.GetNavigation();
        navigation.value = response.data.globalSet.navigation;
        staticLinks.value = response.data.globalSet.statikLinks;
        setCache("navigation", JSON.stringify(navigation.value), cache_expire_time);
        setCache("staticLinks", JSON.stringify(staticLinks.value), cache_expire_time);
        return;
    }
}

type Footer = {
    footer: any,
    footerService: any,
    logo: any
}

//TODO Release 2 - Optimise the code, sync data and fetch data only once
export async function AccessFooterData(footer: any, footerService: any, logo: any) {
    if (getCache("footer")) {
        const data = JSON.parse(getCache("footer")) as Footer;

        if(data.footer) {
            footer.value = data.footer;
        }

        if(data.footerService) {
            footerService.value = data.footerService;
        }

        if(data.logo) {
            logo.value = data.logo;
        }

        syncDatainStorage("footer", data, "footer");
        return;
    } else {
        const response = await client.GetFooter();
        
        footer.value = response.data.globalSet.footerNavigation;
        footerService.value = response.data.globalSet.footerServiceNavigation;
        logo.value = response.data.globalSet.footerLogo;

        const data: Footer = {
            footer: response.data.globalSet.footerNavigation,
            footerService: response.data.globalSet.footerServiceNavigation,
            logo: response.data.globalSet.footerLogo
        }

        setCache("footer", JSON.stringify(data), cache_expire_time);
        return;
    }
}

export async function AccessNewsListing(limit: number = 100) {

    return await client.GetNewsListing(limit).then((res) => {
        News.value = res.data.entries;
        return true;
    }).catch((err) => {
        return false;
    });
}

export async function AccessAppointmentsListing(limit: number = 100, appointmentCategory: string|null = null, appointmentOccasions: Array<appointmentOccasions> = []) {
    return await client.GetAppointmentListing(limit,appointmentCategory, appointmentOccasions).then((res) => {
        Appointments.value = res.data.entries;
        return true;
    }).catch((err) => {
        console.error(err.message);
        return false;
    });
}