<template>
  <div v-if="items && items.length > 0" class="space-y-4 product-slider lg:order-1 lg:col-start-1 lg:col-end-7">
    <swiper v-if="items && items.length > 0" @swiper="Init" @slideChange="Init" :modules="[Thumbs]" :space-between="10" :thumbs="{ swiper: thumb }">
      <swiper-slide class="aspect-[1.4] rounded-lg" v-for="item in items" :key="item.id">
        <Image class="w-full h-full object-cover rounded-lg" :src="item.url" :srcset="item.srcset"
               :alt="item.alt ? item.alt : ''" :title="item.title ? item.title : ''"/>
      </swiper-slide>
    </swiper>
    <swiper :modules="[Thumbs]" watch-slides-progress @swiper="setThumb" :slides-per-view="3" :space-between="10">
      <swiper-slide class="aspect-[1.4] rounded-lg" v-for="item in items" :key="item.id">
        <Image class="w-full h-full object-cover rounded-lg" :src="item.url" :srcset="item.srcset"
               :alt="item.alt ? item.alt : ''" :title="item.title ? item.title : ''"/>
      </swiper-slide>
    </swiper>

    <div :class="`grid grid-cols-2 gap-2 py-0 pr-2 items-center ${items.length > 1 ? '' : 'hidden'}`">
      <div v-if="homestory && homestory[0] && homestory[0].uri" class="col-start-1">
        <router-link :to="'/' + homestory[0].uri">
          <!--TODO replace hardcoded text-->
          <v-btn>Zur Homestory</v-btn>
        </router-link>
      </div>
      <div class="flex gap-2 col-start-2 justify-self-end">
        <button v-on:click="sw.slidePrev()" :disabled="start"
                :class="start ? 'opacity-50 cursor-not-allowed' : ''"
                class="scale-100 opacity-100 grid place-content-center aspect-square w-12 rounded-full transition-all duration-300 active:scale-[0.90]"
                type="button">
          <ArrowLeftCircleIcon class="w-12 text-primary" />
        </button>
        <button v-on:click="sw.slideNext()" :disabled="end"
                :class="end ? 'opacity-50 cursor-not-allowed' : ''"
                class="scale-100 opacity-100 grid place-content-center aspect-square w-12 rounded-full transition-all duration-300 active:scale-[0.90]"
                type="button">
          <ArrowRightCircleIcon class="w-12 text-primary" />
        </button>
      </div>
    </div>
  </div>
  <div v-else class="space-y-4 skeleton aspect-[1.4] lg:order-1 lg:col-start-1 lg:col-end-7">
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Thumbs } from 'swiper/modules';
import {ref} from "vue";
import type { Image, homeStoryLink } from "@/types";
import {ArrowLeftCircleIcon, ArrowRightCircleIcon} from "@heroicons/vue/24/outline";

const thumb = ref(null);
const end = ref(false);
const start = ref(true);
const sw = ref<any>(null);

function setThumb(sw) {
  thumb.value = sw;
}

const props = defineProps<{
  items: Image[];
  homestory: homeStoryLink[];
}>();

function Init(e) {
  // e is swiper event => https://swiperjs.com/vue#swiper-events
  sw.value = e;

  if(e.isEnd) {
    end.value = true;
    start.value = false;
  } else if(e.isBeginning) {
    start.value = true;
    end.value = false;
  } else {
    start.value = false;
    end.value = false;
  }
}
</script>