<template>
    <div class="w-full flex items-center relative z-30">
        <router-link to="/" @click="emit('close')" class="mr-5">
          <MSLogoSm class="w-6" />
        </router-link>
        <v-divider vertical></v-divider>
        <router-link to="/search" @click="emit('close')">
          <Search class="w-4 h-4 ml-5" />
        </router-link>
        <button @click="emit('close')" type="button" title="close" class="ml-auto">
            <Close class="w-4 h-4" />
        </button>
    </div>
</template>

<script setup lang="ts">
import Close from '@/icons/Close.vue'
import Search from '@/icons/Search.vue'
import MSLogoSm from "@/icons/MSLogoSm.vue";

const emit = defineEmits(['close'])

</script>
