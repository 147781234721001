<template>
    <Offcanvas @close="emit('close')" :active position="right" class="z-50">
        <template #content="{ close }">
            <div ref="wrapper"
                class="lg:relative lg:z-40 bg-gray min-h-[100dvh] h-max overflow-y-auto flex flex-col relative w-screen lg:w-[40vw] lg:min-w-[600px] login text-text">
                <button @click="close()" class="w-6 absolute top-8 right-6 lg:top-14 lg:right-12">
                    <XMarkIcon class="w-full" />
                </button>
                <div class="p-6 lg:p-12">
                    <h2 class="font-bold text-primary mb-8 mt-0 text-4xl pr-6"
                        v-html="'Newsletter'">
                    </h2>
                  <div id="newsletter-register" class="space-y-6 flex flex-col" method="post" accept-charset="UTF-8">
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
                            <div class="col-start-1 col-end-2 bg-white rounded-full">
                                <div class="minimal-input">
                                    <v-select menu-icon="mdi-chevron-down" v-model="data.salutation" hide-details
                                        name="salutation" :rules="[rules.required]" label="Anrede"
                                        :items="['Divers', 'Herr', 'Frau']">
                                        <template v-slot:append-inner="{ isFocused }">
                                            <div class="w-7 transition-transform duration-300"
                                                :class="isFocused.value ? 'rotate-180' : 'rotate-0'">
                                                <ChevronDownIcon class="w-full" />
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <div class="md:col-start-1 md:col-end-2 minimal-input">
                                <v-text-field v-model="data.firstName" single-line name="firstName" label="Vorname">
                                </v-text-field>
                            </div>
                            <div class="md:col-start-2 md:col-end-3 minimal-input">
                                <v-text-field v-model="data.lastName" single-line name="lastName" label="Nachname">

                                </v-text-field>
                            </div>
                            <div class="md:col-start-1 md:col-end-2 minimal-input">
                                <v-text-field v-model="data.phoneNumber" single-line label="Telefonnummer" name="phoneNumber">
                                </v-text-field>
                            </div>
                            <div class="md:col-start-2 md:col-end-3 minimal-input">
                                <v-text-field :rules="[rules.required]" type="email" name="email" single-line
                                    v-model="data.email" label="E-Mail*"></v-text-field>
                            </div>
                            <div class="md:col-start-1 md:col-end-2 minimal-input">
                                <v-text-field single-line v-model="data.zip" label="PLZ"></v-text-field>
                            </div>
                            <div class="md:col-start-2 md:col-end-3 minimal-input">
                                <v-text-field v-model="data.city" name="city" single-line label="Ort">
                                </v-text-field>
                            </div>
                            <div class="md:col-start-1 md:col-end-4 minimal-input">
                              <p class="text-sm py-3 pl-2 content">Infos dazu, wie wir mit Ihren Daten umgehen, finden Sie in unserer <a class="hover:text-primary whitespace-nowrap" href="/datenschutz" target="_blank">Datenschutzerklärung</a>.
                                <br class="hidden min-[1915px]:block">Sie&nbsp;können sich jederzeit kostenfrei abmelden.</p>
                                <v-btn :disabled="!data.email" class="w-max" type="submit" @click="HandleSubmit">Abschicken</v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Offcanvas>
</template>

<script setup lang="ts">
import { XMarkIcon, ChevronDownIcon } from "@heroicons/vue/24/outline";
import Offcanvas from "@/snippets/Offcanvas.vue";
import { ref } from "vue";
import craft from "@/api/craft";

const props = defineProps<{
    active: Boolean;
    email: string;
}>();

const emit = defineEmits(['close']);

const data = ref({
    salutation: 'Anrede',
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: props.email,
    zip: "",
    city: "",
    honeypot: "",
});

function HandleSubmit() {
    craft.SubscribeToNewsletter(data.value);
}


const rules = {
    required: (value) => !!value || "Pflichtfeld"
};

</script>