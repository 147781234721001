import { img } from '.'


export const consultant = `
query Consultant($slug: [String]) {
    entries(slug: $slug) {
      ... on pages_default_Entry {
        id
        relationConsultantHouses {
          ... on consultant_default_Entry {
            id
            headlingConsultant
            phoneNumber
            mobilePhone
            lastname
            firstname
            emailAddress
            title_consultant
            avatar ${img}
            qualification
            consultantPageLink {
                id
                url
            }
          }
        }
      }
      ... on appointments_default_Entry {
        id
        relationConsultantAppointment {
          ... on consultant_default_Entry {
            id
            headlingConsultant
            phoneNumber
            mobilePhone
            lastname
            firstname
            emailAddress
            title_consultant
            avatar {
              id
              width
              height
              orgUrl: url
              url(format: "webp")
              smUrl: url(height: 100, format: "webp")
              hasFocalPoint
              focalPoint
              format
              alt
              title
            }
            qualification
            consultantPageLink {
              id
              url
            }
          }
        }
      }
      ... on starseite_starseite_Entry {
        id
        relationConsultantHouses {
          ... on consultant_default_Entry {
            id
            headlingConsultant
            phoneNumber
            mobilePhone
            lastname
            firstname
            emailAddress
            title_consultant
            avatar ${img}
            qualification
            consultantPageLink {
                id
                url
            }
          }
        }
      }
      ... on house_default_Entry {
        id
        relationConsultantHouses {
          ... on consultant_default_Entry {
            id
            headlingConsultant
            phoneNumber
            mobilePhone
            lastname
            firstname
            emailAddress
            title_consultant
            avatar ${img}
            qualification
            consultantPageLink {
                id
                url
            }            
          }
        }
      }
      ... on news_default_Entry {
        id
        relationConsultantHouses {
          ... on consultant_default_Entry {
            id
            headlingConsultant
            phoneNumber
            mobilePhone
            lastname
            firstname
            emailAddress
            title_consultant
            avatar ${img}
            qualification
            consultantPageLink {
                id
                url
            }
          }
        }
      }
    }
    globalSets(handle: "defaultConsultant") {
      handle
      ... on defaultConsultant_GlobalSet {
        id
        name
        consultant {
          ... on consultant_default_Entry {
            id
            headlingConsultant
            phoneNumber
            mobilePhone
            lastname
            firstname
            emailAddress
            title_consultant
            avatar ${img}
            qualification
            consultantPageLink {
                id
                url
            }
          }
        }
      }
    }
    categories(group: "houseStyles") {
      title
      id
    }
  }  
`