<template>
    <h3>Ihr Profilbild</h3>
    <div class="relative z-0 w-1/3">
        <label @drop="handleDrop" @dragover="handleDragOver" @dragleave="handleDragEnd"
            :class="`w-full aspect-[1.2] border border-dashed rounded-sm bg-white grid place-content-center overflow-hidden relative ${image ? 'bg-opacity-20' : ''}`">
            <input ref="input" @change="handleChange" type="file" id="photo" class="hidden" name="photo"
                accept="image/png,image/jpeg">
            <template v-if="!image && !isLoading">
                <v-icon class="mx-auto pointer-events-none" size="x-large" icon="mdi-upload" />
                <p class="text-center pointer-events-none">Drag and Drop</p>
                <p class=" text-sm text-center pointer-events-none">clicken um eine Datei auszuwählen</p>
            </template>
            <template v-else-if="image && !isLoading">
                <img :src="image" alt="profile image"
                    class="pointer-events-none h-full w-auto object-contain absolute left-1/2 -translate-x-1/2 rounded-lg p-4">
            </template>
            <div v-else-if="isLoading" class="absolute pointer-events-none top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
                <v-progress-circular color="primary" indeterminate></v-progress-circular>
            </div>
            <div v-if="isDraged || isLoading"
                :class="`absolute pointer-events-none w-full h-full bg-text transition-opacity ${isDraged || isLoading ? 'opacity-40' : 'opacity-0'} `">
            </div>
        </label>
        <div v-if="image && !isDraged && !isLoading" class="absolute top-0 right-0" >
            <v-btn @click="clearFile" color="text" icon="mdi-delete" variant="text"></v-btn>
        </div>
    </div>
    <input type="hidden" name="deletePhoto" :value="image ? 0 : 1">
</template>

<script setup lang="ts">

import { ref } from "vue";

const prosp = defineProps<{
    image: string | null;
}>();
const isDraged = ref(false);
const image = ref<string | null>(prosp.image);

const isLoading = ref(false);
const input = ref<HTMLInputElement | null>(null);
let enterTarget: EventTarget | null = null;

function clearFile() {
    if (input.value) {
        input.value.value = '';
        image.value = null;
    }
}

function handleDrop(e: DragEvent) {
    e.preventDefault();

    if (e.dataTransfer.files) {
        const file = e.dataTransfer.files[0];
        isDraged.value = false;
        accessFiles(file);
    }
}

function handleDragOver(e: DragEvent) {
    e.preventDefault();
    isDraged.value = true;
    enterTarget = e.target;
}

function handleDragEnd(e: DragEvent) {
    if (enterTarget == e.target) {
        e.preventDefault();
        isDraged.value = false;
    }
}
function handleChange(e: Event) {
    const target = e.target as HTMLInputElement;

    if (!target.files) return;
    const file = target.files?.[0];
    accessFiles(file);
}

function accessFiles(file: File) {

    const reader = new FileReader();

    reader.onload = (e) => {
        setTimeout(() => {
            image.value = e.target.result.toString();
            isLoading.value = false;
        }, 1500)
    };

    if (file) {
        isLoading.value = true;
        reader.readAsDataURL(file);
    }
}

</script>