<template>
  <div class="mt-12 flex flex-col gap-4 justify-between flex-grow">
    <nav>
      <ul v-if="navigation" class="space-y-4 uppercase">
        <li class="relative group cursor-pointer hover:text-primary" v-for="link in navigation">
          <button v-if="link?.children && link?.children.length > 0" @click="setSubMenuState(link)" type="button"
            :class="{ 'text-primary': link.active || ComparePathWithChildUri(link) }"
            class="text-xl font-bold uppercase w-full text-left">
            <span>{{ link.heading }}</span>
            <RedArrow class="w-5 absolute top-1/2 -translate-y-1/2 transition-all"
              :class="link.active ? 'right-0 opacity-100' : 'opacity-0 right-[5%] group-hover:opacity-100 group-hover:right-0'" />
          </button>
          <router-link v-else-if="link.navLink[0].isElement && link.navLink[0].element?.uri" @click="emit('linkClicked')" active-class="text-primary"
            :to="link.navLink[0].isElement ? FormatLink(link.navLink[0].element?.uri) : link.navLink.url"
            class="text-xl font-bold">{{ link.heading ? link.heading : link.navLink.element?.title }}</router-link>
        </li>
      </ul>
      <ul v-else>
        <template v-for="child in 6">
          <div class="w-full h-6 skeleton my-1.5 max-w-[80%] rounded-lg">
          </div>
        </template>
      </ul>
    </nav>
    <v-divider></v-divider>
    <nav>
      <ul v-if="staticLinks" class="space-y-3">
        <li v-for="link in staticLinks" class="hover:text-primary w-max">
          <template v-if="link.typeHandle == 'statischeLinks'">
            <router-link @click="emit('linkClicked')"
                         v-if="link.navLink[0].isElement && link.navLink[0].element?.uri"
              :to="link.navLink[0].isElement ? FormatLink(link.navLink[0].element.uri) : link.navLink[0].url">
              {{ link.heading ? link.heading : link.navLink[0].element?.title }}
            </router-link>
          </template>
          <template v-if="link.typeHandle == 'statischerText'">
            <template v-if="link.plainTextKey == 'catalog'">
              <router-link v-if="auth" :to="auth ? '/user/downloads' : '/user/login'">
                <span class="cursor-pointer">{{ link.plainText }}</span>
              </router-link>
              <a class="block cursor-pointer" v-else @click="openLogin.open = true">
                {{ link.plainText }}
              </a>
            </template>
            <template v-if="link.plainTextKey == 'community'">
              <router-link v-if="auth" :to="auth ? '/user' : '/user/login'">
                <span class="cursor-pointer">{{ link.plainText }}</span>
              </router-link>
              <a class="block cursor-pointer" v-else @click="openLogin.open = true">
                {{ link.plainText }}
              </a>
            </template>
          </template>
        </li>
      </ul>
      <ul v-else>
        <template v-for="child in 5">
          <div class="w-full h-6 skeleton my-1.5 max-w-[75%] rounded-lg">
          </div>
        </template>
      </ul>
    </nav>
    <v-divider></v-divider>
    <ZipInput color="black" />
  </div>
</template>

<script setup lang="ts">
import ZipInput from './ZipInput.vue'
import RedArrow from '@/icons/RedArrow.vue'
import type { NavigationItem } from "@/types";
import { ref, watch, onMounted } from "vue";
import { AccessNavData } from "@/util/accesser";
import { useRoute } from 'vue-router';

import { FormatLink } from "@/util/formater";
import Profile from "@/icons/Profile.vue";
import {openLogin} from "@/stores/login";

const navigation = ref<NavigationItem[]>([]);
const staticLinks = ref<NavigationItem[]>([]);
const props = defineProps<{
  active: boolean,
  activeSubMenu: boolean,
  auth: boolean
}>();
let origin = "";

AccessNavData(navigation, staticLinks);

watch(() => props.active, () => {
  AccessNavData(navigation, staticLinks);
})

const emit = defineEmits(['subMenu', 'linkClicked']);
const route = useRoute();

watch(() => props.activeSubMenu, () => {
  if (!props.activeSubMenu && navigation.value[navigation.value.findIndex((entry) => entry.active)]) {
    navigation.value[navigation.value.findIndex((entry) => entry.active)].active = false;
  }
})
onMounted(() => {
  origin = location.origin;
})

function ComparePathWithChildUri(link: NavigationItem) {
  return link.children.some((entry) => {
    if (entry.navLink[0].isElement && entry.navLink[0].element?.uri) {
      if (FormatLink(entry.navLink[0].element.uri) == route.path) {
        return true
      } else {
        return false;
      };
    }
  })

}

function setSubMenuState(link) {
  emit('subMenu', link.children);

  const index = navigation.value.findIndex((entry) => entry.id === link.id);
  const indexActive = navigation.value.findIndex((entry) => entry.active);

  if (indexActive != index && indexActive != -1) {
    navigation.value[indexActive].active = false;
  }

  if (!navigation.value[index]?.active) {
    navigation.value[index].active = true;
  }
}
</script>