<template>
  <div class="space-y-12" v-if="user">
    <h1 class="mt-12 h2">Willkommen in Ihrem Konto{{ user ? ', ' + user.fullName : '' }}</h1>
    <!-- <div class="grid grid-cols-4 gap-6">
            <div v-for="i in 4" class="bg-gray flex flex-col items-center w-full rounded-lg py-6 gap-6">
                <Heart class="w-8" />
                <h3>Ihre Meisterstücke</h4>
                <h3 class="uppercase opacity-50">Häuser merkliste</h4>
            </div>
        </div> -->
    <!-- <div class="grid grid-cols-2 gap-6">
            <div v-for="i in 2" class="bg-gray flex flex-col items-center w-full rounded-lg py-16 px-16 gap-6">
                <h3>Mein Grundstück</h4>
                <p class="uppercase opacity-90 font-semibold text-center">
                    Hier kann der User Informationen von seinem Grundstück
                    hinterlegen (Anschrift, Größe, Bilder etc.).Gute
                    Voraussetzung für einen Informationsaustausch mit dem
                    Berater.
                </p>
            </div>
        </div> -->

    <!-- Wishlist items -->
    <section>
      <h2 class="h2-s">Ihre Merkliste</h2>
      <div v-if="items && items.length > 0" class="grid grid-cols-1 gap-8 lg:gap-6">
        <div v-for="item in items" style="--gap-small: 0.75rem; --gap: 2.75rem"
          class="relative animate-fastFade z-0 flex flex-wrap justify-between gap-[var(--gap-small)] [&_picture]:w-[25%] [&_picture]:lg:w-[15%] bg-gray p-2 pb-8 lg:p-4 rounded-lg">
          <Image v-if="item.houseHeaderImage[0]" :src="item.houseHeaderImage[0].url" :srcset="item.houseHeaderImage[0].srcset"
            :alt="item.houseHeaderImage[0].alt ? item.houseHeaderImage[0].alt : ''"
            :title="item.houseHeaderImage[0].title ? item.houseHeaderImage[0].title : ''"
            class="aspect-[1.25] rounded-lg object-cover" />
          <div class="w-[calc(75%-var(--gap-small))] lg:w-[calc(68%-var(--gap))] lg:space-y-4 py-4">
            <a v-if="item.url" :href="item.url" class="hover:text-primary">
              <h3 v-text="item.title"></h3>
            </a>
            <h3 v-else v-text="item.title"></h3>
            <ul class="w-full lg:flex flex-wrap gap-2 hidden">
              <li v-if="item.houseStyles && item.houseStyles[0]"
                class=" bg-white py-1 px-2 rounded-full grid place-content-center text-sm">
                {{ item.houseStyles[0].title }}
              </li>
              <li v-if="item.realEstateTypes && item.houseTypes[0]"
                class=" bg-white py-1 px-2 rounded-full grid place-content-center text-sm">
                {{ item.houseTypes[0].title }}
              </li>
              <li v-if="item.totalLivingArea && item.totalLivingArea > 0"
                class=" bg-white py-1 px-2 rounded-full grid place-content-center text-sm">
                {{ item.totalLivingArea }} m²
              </li>
              <li v-if="item.energeticStandards && item.energeticStandards[0]"
                class=" bg-white py-1 px-2 rounded-full grid place-content-center text-sm">
                {{ item.energeticStandards[0].title }}
              </li>
            </ul>
          </div>
          <ul class="w-full flex flex-wrap gap-2 lg:hidden">
            <li v-if="item.houseStyles && item.houseStyles[0]"
              class=" bg-white py-1 px-2 rounded-full grid place-content-center text-sm">
              {{ item.houseStyles[0].title }}
            </li>
            <li v-if="item.realEstateTypes && item.houseTypes[0]"
              class=" bg-white py-1 px-2 rounded-full grid place-content-center text-sm">
              {{ item.houseTypes[0].title }}
            </li>
            <li v-if="item.totalLivingArea && item.totalLivingArea > 0"
              class=" bg-white py-1 px-2 rounded-full grid place-content-center text-sm">
              {{ item.totalLivingArea }} m²
            </li>
            <li v-if="item.energeticStandards && item.energeticStandards[0]"
              class=" bg-white py-1 px-2 rounded-full grid place-content-center text-sm">
              {{ item.energeticStandards[0].title }}
            </li>
          </ul>
          <div
            class="flex gap-2 items-center absolute bottom-0 right-0 translate-y-1/2 lg:translate-y-0 lg:w-max lg:flex-col lg:items-end lg:justify-between lg:static lg:w-[calc(15%-var(--gap))]">
            <v-btn @click="RemoveFromWishlist(item.id)" :rounded="true" class="!p-0 w-10 !h-10 !min-w-10">
              <Trash class="w-5 stroke-2"></Trash>
            </v-btn>
            <v-btn :href="item.url" text="Infos & Kontakt"></v-btn>
          </div>
        </div>
      </div>
      <div v-else-if="wishlist && wishlist.wishlistItems.length > 0" class="grid grid-cols-1 gap-4 lg:gap-6">
        <div v-for="item in wishlist.wishlistItems.length" style="--gap: 2rem"
          class="relative z-0 flex flex-wrap justify-between gap-[var(--gap)] p-2 pb-8 lg:p-4 rounded-lg">
          <div
            class="aspect-[1.25] w-[calc(25%-var(--gap))] lg:w-[calc(17%-var(--gap))] rounded-lg object-cover skeleton" />
          <div class="w-[calc(75%-var(--gap))] lg:w-[calc(68%-var(--gap))] lg:space-y-4 py-4">
            <h3 class="skeleton w-1/3 h-4"></h3>
            <ul class="w-full lg:flex flex-wrap gap-2 hidden">
              <li v-for="i in 6" class=" bg-white py-2 px-6 rounded-full grid place-content-center text-sm skeleton">
              </li>
            </ul>
          </div>
          <ul class="w-full flex flex-wrap gap-2 lg:hidden">
            <li v-for="i in 6" class=" bg-white py-2 px-6 rounded-full grid place-content-center text-sm skeleton">
            </li>
          </ul>
          <div
            class="flex gap-2 items-center absolute bottom-0 right-0 translate-y-1/2 lg:translate-y-0 lg:flex-col lg:items-end lg:justify-between lg:static lg:w-[calc(15%-var(--gap))]">
            <div class="skeleton w-8 aspect-square rounded-full"></div>
            <div class="skeleton w-20 p-4"></div>
          </div>
        </div>
      </div>
      <div v-else class="pb-8">
        <p class="content">Sie haben noch keine Meisterstücke auf ihrer Merkliste hinzugefügt.</p>
      </div>
      <div class="flex justify-center  mt-16 lg:mt-10"><v-btn href="/hausfinder" text="mehr Häuser hinzufügen"></v-btn>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { loaded } from '@/stores/loading';
import wishlist from '@/stores/wishlist';
import { onMounted, watch, ref, computed } from 'vue';
import client from '@/api/craft';
import { User } from '@/types/user';
import Trash from "@/icons/Trash.vue";
import Image from "@/snippets/Image.vue";
import { useUserStore } from "@/stores/userStore";

const userStore = useUserStore();

const user = computed(() => userStore.getUserData as User);

onMounted(() => {
  loaded.value.animation = true;
  setTimeout(() => {
    loaded.value.active = false;
  }, 300);
});

const items = ref([]);

watch(() => wishlist.value, (value) => {
  if (value.wishlistId !== 0) {
    client.getWishlistItemsByIds(value.wishlistItems)
      .then(res => {
        if (res) {
          items.value = res.data.houseEntries;
        }
      });
  }
});

AccessEntries();

function RemoveFromWishlist(id: string) {
  client.RemoveFromWihlist(id)
    .then(res => {
      if (res) {
        wishlist.value = {
          wishlistId: wishlist.value.wishlistId,
          wishlistItems: wishlist.value.wishlistItems.filter((item: any) => item !== id)
        };
        AccessEntries();
      }
    });
}

function AccessEntries() {
  if (wishlist.value.wishlistItems.length === 0) {
    items.value = []
  } else {
    client.getWishlistItemsByIds(wishlist.value.wishlistItems)
      .then(res => {
        if (res) {
          items.value = res.data.houseEntries;
        }
      });
  }
}
</script>
