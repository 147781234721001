<template>
  <section class="lg:full col-end-12 lg:col-end-11 gap-6 grid lg:grid-cols-[300px_1fr]">
    <SideBar />
    <div>
      <div class="w-full p-6 bg-gray rounded-lg mb-6 lg:mb-12">
        <nav>
          <ul class="flex items-center gap-4">
            <li>
              <router-link active-class="text-primary" to="/user/overview">
                <h3>Überblick</h3>
              </router-link>
            </li>
            <li>
              <router-link active-class="text-primary" to="/user/settings">
                <h3>Kontoverwaltung</h3>
              </router-link>
            </li>
            <!--                        <li>-->
            <!--                            <router-link-->
            <!--                                active-class="text-primary"-->
            <!--                                to="/user/notifications"-->
            <!--                            >-->
            <!--                                <h3>Benachrichtigungen</h3>-->
            <!--                            </router-link>-->
            <!--                        </li>-->
          </ul>
        </nav>
      </div>
      <router-view v-slot="{ Component }">
        <div>
          <component :is="Component" />
        </div>
      </router-view>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import errorStore from "@/stores/error";
import { useUserStore } from "@/stores/userStore";

import { useRoute, useRouter } from "vue-router";
// import userClient from "@/api/user";

import SideBar from "./components/SideBar.vue";
import { User } from "@/types/user";
import { seoData } from "@/api/queries";
import { useHead } from "@unhead/vue";

const user = useUserStore();
const route = useRoute();
const router = useRouter();

fetch("/actions/users/session-info", {
  method: "get",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
})
  .then((res) => res.json())
  .then((data) => {
    if (data.isGuest) {
      router.push("/");
      errorStore.value.message = "Sie sind nicht angemeldet";
      errorStore.value.active = true;
    } else {
      if (route.path === "/user") {
        router.push("/user/overview");
      }
    }
  });

// const csrfName = ref("");
// const csrfToken = ref("");

// const loading = ref(true);
// const anim = ref(true);

// const props = defineProps<{
//     auth: boolean;
// }>();

// const seo = ref(<seoData>{
//   metaDescription: "Erstellen oder verwalten Sie Ihr Konto",
//   metaTitle: "Benutzerprofil | Meisterstück-HAUS"
// });

// const user = ref<User | null>(null);

// type jwtType = {
//     jwt: string;
//     jwtExpiresAt: number;
//     refreshToken: string;
//     refreshTokenExpiresAt: number;
// }

// const jwt = ref<jwtType | null>(null);

// getSEOData();
onMounted(() => {
  // if (!props.auth) {
  //   errorStore.value.message = "Etwas ist falsch gelaufen. Bitte melden Sie sich erneut an.";
  //   errorStore.value.active = true;
  //   location.replace("/");
  // }
  // if (route.path === "/user") {
  //   router.push("/user/overview");
  // }
  // csrfName.value = window.csrfTokenName;
  // csrfToken.value = window.csrfTokenValue;
  // jwt.value = currentUser.value.jwt;
  // userClient.GetUser().then((response) => {
  //     if(response) {
  //       anim.value = false;
  //       setTimeout(() => {
  //           loading.value = false;
  //       }, 300);
  //     } else {
  //       location.replace("/");
  //       errorStore.value.message = "Sie sind nicht angemeldet";
  //       errorStore.value.active = true;
  //     }
  // });
});

// async function getSEOData() {
//   let dataResults = await client.GetUserSEOData();

//   if(dataResults && dataResults.data.entries.length > 0) {
//     seo.value = {
//       metaTitle: dataResults.data.entries[0].metaTitle ?? seo.value.metaTitle,
//       metaDescription: dataResults.data.entries[0].metaDescription ?? seo.value.metaDescription,
//       metaImage: dataResults.data.entries[0].metaImage
//     }
//   }
// }

// useHead({
//   title: () => seo.value?.metaTitle,
//   meta: [
//     {
//       name: "title",
//       content: () => seo.value?.metaTitle,
//     },
//     {
//       property: "og:title",
//       content: () => seo.value?.metaTitle,
//     },
//     {
//       name: "twitter:title",
//       content: () => seo.value?.metaTitle,
//     },
//     {
//       name: "description",
//       content: () => seo.value?.metaDescription,
//     },
//     {
//       property: "og:description",
//       content: () => seo.value?.metaDescription,
//     },
//     {
//       name: "twitter:description",
//       content: () => seo.value?.metaDescription,
//     },
//     {
//       name: "image",
//       content: () =>
//           seo.value && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
//               ? window.location.origin + seo.value?.metaImage[0].url
//               : window.location.origin + seo.value?.globalMetaImage.url,
//     },
//     {
//       property: "og:image",
//       content: () =>
//           seo.value && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
//               ? window.location.origin + seo.value?.metaImage[0].url
//               : window.location.origin + seo.value?.globalMetaImage.url,
//     },
//     {
//       property: "og:image:type",
//       content: () =>
//           seo.value && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
//               ? seo.value?.metaImage[0].format
//               : seo.value?.globalMetaImage.format,
//     },
//     {
//       property: "og:image:width",
//       content: () =>
//           seo.value && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
//               ? seo.value?.metaImage[0].width
//               : seo.value?.globalMetaImage.width,
//     },
//     {
//       property: "og:image:height",
//       content: () =>
//           seo.value && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
//               ? seo.value?.metaImage[0].height
//               : seo.value?.globalMetaImage.height,
//     },
//     {
//       property: "twitter:image",
//       content: () =>
//           seo.value && seo.value?.metaImage[0] && seo.value?.metaImage[0].url
//               ? window.location.origin + seo.value?.metaImage[0].url
//               : window.location.origin + seo.value?.globalMetaImage.url,
//     },
//     {
//       name: "og:site_name",
//       content: () => seo.value?.metaTitle,
//     },
//     {
//       property: "og:url",
//       content: () => window.location.href,
//     },
//   ],
// });
</script>
