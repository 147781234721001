<template>
  <div>
    <h1 class="h2">Ihre Downloads</h1>
    <template v-if="!loading">
      <div v-if="downloadElements && downloadElements.length > 0" v-for="download in downloadElements"
        class="py-3 px-4 rounded-lg bg-gray mb-4">
        <a :href="`/actions/digital-download/download?u=${download.token}`" :download="`${download.filename}`"
          class="text-primary inline"><span class="h4 inline">{{ download.title }}</span></a> <span
          class="text-sm text-black">{{ calcSize(download.size) }}</span>
      </div>
      <div v-else>
        <p>Keine Downloads vorhanden.</p>
      </div>
    </template>

  </div>
</template>

<script setup lang="ts">

import { loaded } from '@/stores/loading';
import { onMounted, ref } from 'vue';
import client from "@/api/user";

type Downloads = {
  format: string;
  title: string;
  token: string;
  filename: string;
  size: number;
}

type DownloadsResponse = {
  success: boolean;
  downloads: Downloads[];
}
const loading = ref(true);
const downloadElements = ref<Downloads[]>([]);

onMounted(() => {
  loaded.value.animation = true;
  setTimeout(() => {
    loaded.value.active = false;
  }, 300);
});

getDownloads();

async function getDownloads() {
  let searchResult = await client.getDownloads() as DownloadsResponse;

  if (searchResult.success) {
    downloadElements.value = searchResult.downloads;
  }

  loading.value = false;
}

function calcSize(size: number) {
  return (size / 1024 / 1024).toFixed(2) + ' MB';
}

</script>
