import { craft } from "./class/craftApiClass";

const client = new craft();

export function initClient(token: string, mode: string) {
    client.token = token;
    // client.endpoint = location.origin + '/api';
    client.endpoint = mode === "dev" ? "http://meisterstueck.dev01.test/api" : location.origin + '/api';
}

export default client;