<template>
    <div class="minimal-input" :class="props.class">
        <v-select
            :bg-color="props.bgColor"
            menu-icon="mdi-chevron-down"
            hide-details
            :label="props.label"
            :items="items ? items : ['Herr', 'Frau', 'Divers']"
            @update:modelValue="handleChange"
        >
            <template v-slot:append-inner="{ isFocused }">
                <div
                    class="w-7 transition-transform duration-300"
                    :class="isFocused.value ? 'rotate-180' : 'rotate-0'"
                >
                    <ChevronDownIcon class="w-full text-[color:current]" />
                </div>
            </template>
        </v-select>
    </div>
</template>

<script setup lang="ts">
import { ChevronDownIcon } from "@heroicons/vue/24/outline";

const emit = defineEmits(["update"]);
const props = defineProps<{
    label: string;
    items: string[];
    bgColor?: string;
    class?: string;
    name?: string
}>();

function handleChange(e) {
    emit("update", e);
}

function Lo(e) {
    console.log(e)
}
</script>
