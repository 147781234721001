<template>
    <div>
        <h1>Nachrichten</h1>
    </div>
</template>

<script setup lang="ts">

import { loaded } from '@/stores/loading';
import { onMounted } from 'vue';

onMounted(() => {
    loaded.value.animation = true;
    setTimeout(() => {
        loaded.value.active = false;
    }, 300);
});

</script>
